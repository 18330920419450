import React from 'react';
import Img, { FluidObject } from 'gatsby-image';

import Container from 'components/ui/Container';

import * as Styled from './styles';
import AppStoreImageLinks from '../AppStoreImageLinks';
import FormatHtml from 'components/utils/FormatHtml';

interface Props {
  title: string;
  subtitle?: string;
  content: React.ReactNode;
  callToAction: string;
  callToActionSubtitle?: string;
  linkTo: string;
  linkText: string;
  appImage: FluidObject;
}

const Banner: React.FC<Props> = ({ title, subtitle, content, callToAction, callToActionSubtitle, appImage }) => (
  <Styled.Banner>
      <Container section> 
      <Styled.BannerText>
        <Styled.BannerTitle>{title}</Styled.BannerTitle>
        <FormatHtml content={content} />
        <Styled.CallToAction>{callToAction}</Styled.CallToAction>
        <Styled.CallToActionSub>{callToActionSubtitle}</Styled.CallToActionSub>
        <AppStoreImageLinks />
      </Styled.BannerText>
    <Styled.Image><Img fluid={appImage} alt="Lively App Screenshots"/></Styled.Image>
    </Container>

  </Styled.Banner>
);

export default Banner;
